"use strict";

var app = {
  mobileMenu: function mobileMenu() {
    var mobileBtn = $("#mobile-burger-btn");
    var pageBody = $("#page-wrap-inner");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
        }
      });
    }
  },
  validateEmail: function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  init: function init() {
    app.mobileMenu();

    $(".projects-carousel").slick({
      infinite: true,
      autoplay: false,
      autoplaySpeed: 5000,
      speed: 300,
      slidesToShow: 2,
      slidesToScroll: 1,
      nextArrow: ".carousel-button-right",
      prevArrow: ".carousel-button-left",
      cssEase: "linear",
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }
};

$(document).ready(function () {
  app.init();
});