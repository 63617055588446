"use strict";

var $gl = $(".projectnav");
var $gl2 = $(".projectgallery");

$gl.slick({
  rows: 0,
  slidesToShow: 8,
  arrows: false,
  draggable: false,
  useTransform: false,
  cssEase: "linear",
  variableWidth: true,
  responsive: [{
    breakpoint: 1000,
    settings: {
      slidesToShow: 4
    }
  }, {
    breakpoint: 940,
    settings: {
      slidesToShow: 3
    }
  }, {
    breakpoint: 900,
    settings: {
      slidesToShow: 5,
      centerMode: true
    }
  }, {
    breakpoint: 800,
    settings: {
      slidesToShow: 5,
      centerMode: true
    }
  }]
});

$gl2.slick({
  rows: 0,
  useTransform: false,
  prevArrow: ".prev-img",
  nextArrow: ".nxt-img",
  fade: true,
  asNavFor: $gl
});

$(".projectnav img").on("click", function () {
  var index = $(this).attr("data-slick-index");
  $gl2.slick("slickGoTo", index);
});